import React from 'react';

import { Page } from 'src/components';

const CGU = () => (
  <Page>
    <Page.Main className="pt-16 mx-auto max-w-screen-2xl">
      <iframe
        title="CGU"
        src="./equilibre-cgu.pdf"
        className="w-full"
        style={{ minHeight: 'calc(100vh - 4rem)' }}
      />
    </Page.Main>
  </Page>
);

export default CGU;
